/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { Search } from 'react-bootstrap-table2-toolkit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';

const { SearchBar } = Search;

const TableSearchBox = (props) => (
    <SearchBox>
        <FontAwesomeIcon icon={faSearch} />
        <SearchBar className="form-control" placeholder=" " {...props} />
    </SearchBox>
);

TableSearchBox.propTypes = {};

export default TableSearchBox;

const SearchBox = styled.div`
  margin-bottom: 10px;
  position: relative;

  svg {
    color: gray;
    left: 10px;
    position: absolute;
    top: 14px;
  }

  input {
    padding-left: 30px;
    height: 44px;
  }
`;
