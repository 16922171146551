import React from 'react';
import styled from 'styled-components';

const TableTotalRecords = (from, to, size, labels) => (
    <Totals>{`${labels.showing || 'showing'} ${from} ${labels.to || 'to'} ${to} ${labels.of || 'of'} ${size}`}</Totals>
);
export default TableTotalRecords;

const Totals = styled.div`
    font-size: 0.8rem;
    margin-top: 10px;
`;
