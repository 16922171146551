/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-unused-vars
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useWindowWidth } from '@react-hook/window-size';
import Loader from 'loader-xn';
import getTableFooter from './TableFooter';
import TableSearchBox from './TableSearchBox';
import 'bootstrap/dist/css/bootstrap.min.css';

const Table = ({
    data, columns, loading, color, labels, noPaginate, ...rest
}) => {
    const width = useWindowWidth();
    const isSmallMobile = width <= 380;

    return (
        <TableContainer>
            <ToolkitProvider data={data} columns={columns} keyField="id" search>
                {(props) => (
                    <>
                        <TableSearchBox {...props.searchProps} />
                        <TableScroll>
                            <BootstrapTable
                                {...props.baseProps}
                                keyField="id"
                                pagination={noPaginate ? null : getTableFooter(isSmallMobile, color, labels)}
                                filter={filterFactory()}
                                noDataIndication={() => (loading
                                    ? <Loader color={color} loading />
                                    : <NoData>{labels.noData}</NoData>)}
                                {...rest}
                            />
                        </TableScroll>
                    </>
                )}
            </ToolkitProvider>
        </TableContainer>
    );
};

Table.propTypes = {
    data: PropTypes.instanceOf(Array),
    columns: PropTypes.instanceOf(Array).isRequired,
    loading: PropTypes.bool,
    labels: PropTypes.shape({
        noData: PropTypes.string,
        showing: PropTypes.string,
        show: PropTypes.string,
        to: PropTypes.string,
        of: PropTypes.string,
    }),
    color: PropTypes.string,
};

Table.defaultProps = {
    data: [],
    loading: false,
    labels: {
        noData: 'No Data',
        showing: 'Showing',
        show: 'Show',
        to: 'to',
        of: 'of',
    },
    color: 'black',
};

export default Table;

const TableContainer = styled.div`
  th {
    background-color: #eee;
  }

  .react-bootstrap-table-pagination {
    display: flex;
    margin: 10px;
    padding: 10px 0;

    @media (max-width: 576px) {
      flex-direction: column-reverse;
    }

    &-list {
      .pagination {
        justify-content: flex-end;

        @media (max-width: 576px) {
          margin-bottom: 30px;
        }
      }
    }
  }

  .react-bootstrap-table-pagination-list-hidden {
    display: none;
  }
`;
const NoData = styled.div`
  text-align: center;
  font-style: italic;
`;

const TableScroll = styled.div`
  overflow-x: auto;
`;
