import paginationFactory from 'react-bootstrap-table2-paginator';
import TablePagination from './TablePagination';
import TableSelectPageSize from './TableSelectPageSize';
import TableTotalRecords from './TableTotalRecords';

const getTableFooter = (isSmallMobile, color, labels) => paginationFactory({
    page: 1,
    paginationSize: 3,
    alwaysShowAllBtns: false,
    showTotal: true,
    withFirstAndLast: !isSmallMobile,
    hidePageListOnlyOnePage: true,
    pageButtonRenderer: ({ page, active, onPageChange }) => TablePagination({
        page, active, onPageChange, color,
    }),
    sizePerPageRenderer: ({ currSizePerPage, onSizePerPageChange }) => TableSelectPageSize(({ currSizePerPage, onSizePerPageChange, labels })),
    paginationTotalRenderer: (from, to, size) => TableTotalRecords(from, to, size, labels),
});

export default getTableFooter;
