import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft as arrowLSolid } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleLeft';
import { faArrowAltCircleRight as arrowRSolid } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleRight';
import { faArrowAltCircleLeft as arrowLRegular } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleLeft';
import { faArrowAltCircleRight as arrowRRegular } from '@fortawesome/free-regular-svg-icons/faArrowAltCircleRight';

const TablePagination = ({
    page, active, onPageChange, color,
}) => {
    const handleClick = (event) => {
        event.preventDefault();
        onPageChange(page);
    };

    if (page === '<<' || page === '>>') {
        return (
            <ArrowButton key={page} onClick={handleClick} color={color}>
                <FontAwesomeIcon icon={page === '<<' ? arrowLSolid : arrowRSolid} />
            </ArrowButton>
        );
    }

    if (page === '<' || page === '>') {
        return (
            <ArrowButton key={page} onClick={handleClick} right={page === '>'} color={color}>
                <FontAwesomeIcon icon={page === '<' ? arrowLRegular : arrowRRegular} />
            </ArrowButton>
        );
    }

    return (
        <PageButton key={page} onClick={handleClick} active={active} color={color}>
            {page}
        </PageButton>
    );
};

TablePagination.propTypes = {
    page: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    onPageChange: PropTypes.func.isRequired,
    color: PropTypes.string.isRequired,
};

export default TablePagination;

const ArrowButton = styled.button`
  display: flex;
  border: none;
  background: transparent;
  color: ${(props) => props.color};
  font-size: 25px;
  margin-left: ${(props) => (props.right ? '8px' : '0')};

  &:focus {
    outline: 0;
  }
`;

const PageButton = styled.button`
  color: ${(props) => (props.active ? 'white' : 'black')};
  background-color: ${(props) => (props.active ? props.color : 'white')};
  border: ${(props) => (props.active ? 'none' : 'solid 1px lightgray')};
  border-radius: 50%;
  height: 38px;
  margin-left: 10px;
  width: 38px;

  &:focus {
    outline: 0;
  }
`;
