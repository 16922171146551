import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SelectField from 'select-field-xn';

const PaginationOptions = [
    { value: 5, label: '5' },
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 50, label: '50' },
];

function TableSelectPageSize({ currSizePerPage, onSizePerPageChange, labels }) {
    return (
        <Container>
            <SelectField
                name="pageSize"
                label={labels.show}
                defaultValue={currSizePerPage}
                options={PaginationOptions}
                onChange={(e) => onSizePerPageChange(e.target.value)}
            />
        </Container>
    );
}

TableSelectPageSize.propTypes = {
    currSizePerPage: PropTypes.number.isRequired,
    onSizePerPageChange: PropTypes.func.isRequired,
    labels: PropTypes.instanceOf(Object).isRequired,
};

export default TableSelectPageSize;

const Container = styled.div`
    width: 100px;
`;
